.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    width: 80%;
    border-bottom: 2px solid #808080;
}

.typingField {
    padding-bottom: 10px;
}

.submitOps {
    display: flex;
    justify-content: space-between;
    width: 140px;
}
