/*
 * InfoDisplay.module.css
*/

.infoDisplay {
    composes: display from "./DisplayMan.module.css";
    width: 400px;
    height: 500px;
}

.infoDisplay img {
    width: 80%;
}

img.pokeBullet {
    width: 15px;
    height: 100%;
    padding-right: 10px;
}

.listRow {
    align-items: center;
    justify-content: space-between;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 20px;
}

.listRow a {
    display: inline-block;
}

.listRow:last-of-type {
    padding-bottom: 25px;
}

.credit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.names {
    display: flex;
}