.shuckle {
    position: absolute;
}

.shuckleShiny {
    position: absolute;
}

.egg {
    position: absolute;
}

.underbite {
	transform: rotate(180deg);
}

.love {
     position: absolute;
}

.sing {
     position: absolute;
}

.chomp {
    position: absolute;
    z-index: 2;
}

.anger {
    position: absolute;
    height: 32px;
    width: 32px;
}

.slash {
    position: absolute;
    height: 36px;
    width: 36px;    
    z-index: 2;
}

.cloudCover {
    position: absolute;
    height: 64px;
    z-index: 1;
}
