.storageWrapper {
    height: 0px;
    width: 0px;
}

.storageWrapper button {
    background: none;
    border: none;
}

.header {
    background: #EBB7C6; 
    border-top-left-radius: 5px;
}

.header1 {
    min-width: 66px;
    max-width: 66px;
}

.leftColumn {
    min-width: 50.28px;
    max-width: 50.28px;
}

.storage {
	position: relative;
	top: 16px;
	left: -435px;

    width: 125.08px;
    text-align: center;
    background: #e090a8;
    border: 3px solid #925E6D;
    border-radius: 10px;
}

.draggable img {
    cursor: move;
    height: 64px;
}

.item {
    background: #ffffff;
}

.expansionArrow {
	position: relative;
	top: 18px;
	left: -395px;
}

.expansionArrow img {
    width: 40px;
    height: 40px;
}
