/* 
 * ShopDisplay.module.css
*/

.header {
    width: 80%;
    padding-top: 2em;
    padding-bottom: 20px;
    border-bottom: 2px solid #808080;
}

.subheader {
    width: 80%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 15px;
}

.shuckleDisplay {
    composes: display from "./DisplayMan.module.css";
    width: 400px;
    height: 575px;
}

.shucklePic {
    width: 70%;
    padding-bottom: 15px;
}

.sellInfo {
    display: flex;
    height: 48px;
}

.sellInfo p {
    font-size: 2rem;
    font-weight: bold;
    margin-top: -1%;
}

.sellInfo img {
    height: 30px;
    width: 25px;
}

.shuckleDisplay button {
    position: relative; 
    width: 100px; 
    height: 25px;
}

.exitIcon button{
    cursor: pointer;
    background: none;
    border: none;
    width: 60px;
    height: 75px;
    margin-left: 65%;
    margin-top: -2.5%;
}

.shopDisplay {
    composes: display from "./DisplayMan.module.css";
    width: 400px;
    height: 575px;
}

.menu {
    width: 100%;
    margin-top: 3.5%;
    justify-content: space-between;
}

.rowDisplay {
    display: flex;
    font-size: 2rem;
    width: 100%;
    height: 75px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2.5%;
}

.rowDisplay:first-of-type {
    padding-top: 5%;
}

.item {
    display: flex;
}

.item p {
    margin-top: -10%;
}

.item img {
    width: 64px;
    height: 64px;
}

.lemonade img {
    height: 64px;
    width: inherit;
    padding-left: 6px;
    padding-right: 6px;
}

.attempt {
    display: block;
    width: 100px;
}

.attempt button {
    width: 75px;
    height: 25px;
    margin-left: 2.5%;
}

.priceInfo {
    display: flex;
    height: 25px;
}

.priceInfo img {
    height: 30px;
    width: 25px;
}

.icon button{
    cursor: pointer;
    background: none;
    border: none;
    width: 60px;
    height: 75px;
}

button.shuckleTabActive {
    background: none;
    border: none;
    position: absolute;

    background-image: url(../../assets/shuckle_tab_ACTIVE.png); 
    background-repeat: no-repeat;
    background-position: 50% 50%;

    width: 121px;
    height: 100px;

    margin-top: -18%;
    margin-left: -19%;

    cursor: pointer;
}

button.shuckleTabInactive {
    background: none;
    border: none;
    position: absolute;

    background-image: url(../../assets/shuckle_tab_INACTIVE.png); 
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100px;
    width: 120px;

    margin-top: -18%;
    margin-left: -19%;
    cursor: pointer;
}

button.shopTabActive {
    background: none;
    border: none;
    position: absolute;

    background-image: url(../../assets/shop_tab_ACTIVE.png); 
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100px;
    width: 120px;

    margin-top: -18%;
    margin-left: -74.2%;
    cursor: pointer;
}

button.shopTabInactive {
    background: none;
    border: none;
    position: absolute;

    background-image: url(../../assets/shop_tab_INACTIVE.png); 
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100px;
    width: 120px;

    margin-top: -18%;
    margin-left: -74%;
    cursor: pointer;
}

.shuckleTab span {
    display: none;
}
