/*
 * DisplayMan.module.css
*/

.menuBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 900px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 2px solid #808080;
}

.pHeader {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    left: 15px;
    width: 114px;
    font-size: 3rem;
    padding-left: 5px;
    padding-right: 5px;
}

/* POKEDOLLAR IMG */
.pHeader img {
    height: 35px;
    margin-right: 12px;
    padding-top: 6px;
}

.gameTitle img {
    height: 35px;
    padding-top: 6px;
}

.backdrop {
	z-index: 1;
	position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
	background-color: rgba(0, 0, 0, 0.75);
    justify-content: center;
}

.display {
	z-index: 2;
    position: absolute;

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 400px;

    top: -55px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 420px;

    /* Center text/elements */
	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 12px;
    border: 6px solid #8b606d;
	background-color: #ffffff;
}

.icon button:first-of-type {
    margin-left: -50%;
}

.icon button {
    cursor: pointer;
    background: none;
    border: none;
}

.settingsIcon {
    height: 30px;
    width: 30px;
}

