[class *= "keyBox"] {
    border: 1px solid #939393;
    padding: 0;
    margin: 0 6px 6px 6px;
    height: 48px;

    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
}

.keyBox {
    width: 40px;
}

.keyBoxLong {
    width: 64px;
    background-color: #dadada;
}

.keyRow {
    display: grid;
    align-items: center; /* idk */
    flex-wrap: nowrap;
    padding-top: 4px;
}

.keyboard {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 4px;
    padding-top: 20px;
    max-width: 600px;
}
