img.item {
	cursor: move;
	width: 64px;
	height: 64px;
}

img.lemonade {
	cursor: move;
	height: 64px;
}

