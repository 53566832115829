body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
    margin: 0; 
    padding: 0;

    width: 100%;
    height: 100%;
}

.LeftMenu {
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.NavSandwich {
  display: flex;
  background: inherit;
  border: inherit;
  padding-left: 12px;
}

.HelpButton {
  display: flex;
  background: inherit;
  border: inherit;
}

.RightMenu {
  display: flex;
  justify-content: flex-end;
  padding-right: 4px;
}
