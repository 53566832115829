.gameRow {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
}

.pokeSprite {
    width: 64px;
    height: 48px;
}

img.unown {
    width: 55px;
    height: 55px;
    object-fit: cover;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(10);
    }
    50% {
        transform: translateY(-15px);
    }
}

.bounceAnimation {
    animation: bounce 1s infinite;
}

.guessBox {
	display: inline-flex;
	border: 2px solid #c2c3c5;
	width: 40px;
	height: 40px;
	font-size: 1.5rem;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
}

.gameSpace {
    margin-top: 25px;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
	justify-content: center;
	align-items: center;
    grid-gap: 5px;
    height: 380px;
    width: 500px;
}
